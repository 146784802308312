import { useState } from "react";
import { defineMessages } from "react-intl";
import TextField from "./TextField";
import Icon from "../../common/components/Icon";
import {
  strHasLowerCase,
  strHasNumber,
  strHasSpecialChar,
  strHasUpperCase,
  isStrLenGte,
} from "../utils/str-utils";

defineMessages({
  "min-12-char-password-error": {
    id: "min-12-char-password-error",
    defaultMessage: "{label} must be at least 12 characters long",
  },
  "password-must-include-lowercase-error": {
    id: "password-must-include-lowercase-error",
    defaultMessage: "{label} must include at least 1 lowercase letter",
  },
  "password-must-include-uppercase-error": {
    id: "password-must-include-uppercase-error",
    defaultMessage: "{label} must include at least 1 uppercase letter",
  },
  "password-must-include-digit-error": {
    id: "password-must-include-digit-error",
    defaultMessage: "{label} must include at least 1 digit",
  },
  "password-must-include-special-char-error": {
    id: "password-must-include-special-char-error",
    defaultMessage: "{label} must include at least 1 special character",
  },
});

defineMessages({
  "min-12-char-password-error": {
    id: "min-12-char-password-error",
    defaultMessage: "{label} must be at least 12 characters long",
  },
  "password-must-include-lowercase-error": {
    id: "password-must-include-lowercase-error",
    defaultMessage: "{label} must include at least 1 lowercase letter",
  },
  "password-must-include-uppercase-error": {
    id: "password-must-include-uppercase-error",
    defaultMessage: "{label} must include at least 1 uppercase letter",
  },
  "password-must-include-digit-error": {
    id: "password-must-include-digit-error",
    defaultMessage: "{label} must include at least 1 digit",
  },
  "password-must-include-special-char-error": {
    id: "password-must-include-special-char-error",
    defaultMessage: "{label} must include at least 1 special character",
  },
});

const PasswordField = (props) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const { autoComplete, disableValidation = false } = props || {};

  const min12CharactersValidator = {
    isValid: (value) => isStrLenGte(value, 12),
    intlMessageDescriptor: {
      id: "min-12-char-password-error",
      defaultMessage: "{label} must be at least 12 characters long",
    },
  };

  const includesLowercaseValidator = {
    isValid: strHasLowerCase,
    intlMessageDescriptor: {
      id: "password-must-include-lowercase-error",
      defaultMessage: "{label} must include at least 1 lowercase letter",
    },
  };

  const includesUpperCaeValidator = {
    isValid: strHasUpperCase,
    intlMessageDescriptor: {
      id: "password-must-include-uppercase-error",
      defaultMessage: "{label} must include at least 1 uppercase letter",
    },
  };

  const includesNumberValidator = {
    isValid: strHasNumber,
    intlMessageDescriptor: {
      id: "password-must-include-digit-error",
      defaultMessage: "{label} must include at least 1 digit",
    },
  };

  const includesSpecialCharValidator = {
    isValid: strHasSpecialChar,
    intlMessageDescriptor: {
      id: "password-must-include-special-char-error",
      defaultMessage: "{label} must include at least 1 special character",
    },
  };

  const togglePassword = (event) => {
    event.preventDefault();
    setPasswordVisible(!isPasswordVisible);
  };

  const validators = disableValidation
    ? []
    : [
        min12CharactersValidator,
        includesUpperCaeValidator,
        includesLowercaseValidator,
        includesNumberValidator,
        includesSpecialCharValidator,
      ];

  return (
    <div className="position-relative">
      <TextField
        {...{
          autoComplete: autoComplete || "on",
          name: "password",
          type: isPasswordVisible ? "text" : "password",
          ...props,
        }}
        validators={validators}
      />
      <span
        role="button"
        tabIndex={-1}
        aria-label="toggle password visibility"
        className="hide-show"
        onClick={togglePassword}
        onKeyUp={togglePassword}
      >
        {isPasswordVisible ? (
          <Icon
            icon="view-off"
            alt="hide password icon"
            className="password-eye"
            fill="currentColor"
          />
        ) : (
          <Icon
            icon="view-1"
            alt="show password icon"
            className="password-eye"
            fill="currentColor"
          />
        )}
      </span>
    </div>
  );
};

export default PasswordField;
