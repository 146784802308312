import { useMutation, gql } from "@apollo/client";

const LoginMutation = gql`
  mutation login($username: String, $email: String, $password: String) {
    loginWithPassword(
      username: $username
      email: $email
      plainPassword: $password
    ) {
      id
      token
      tokenExpires
    }
  }
`;

const useLoginWithPassword = () => {
  const [loginWithPasswordMutation, { client }] = useMutation(LoginMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ["currentClient"],
    context: {
      errorHandled: true,
    },
  });

  const loginWithPassword = async ({ username, email, password }) => {
    try {
      const result = await loginWithPasswordMutation({
        variables: { username, email, password },
      });

      const { id } = result.data.loginWithPassword;
      if (id) {
        await client.resetStore();
      }
      return { success: Boolean(id) };
    } catch (e) {
      if (e?.message?.includes("auth failed")) {
        return { success: false, error: e };
      }

      // Rethrow to log the error
      throw e;
    }
  };

  return { loginWithPassword };
};

export default useLoginWithPassword;
