import { useIntl } from "react-intl";
import { useRouter } from "next/router";

import Icon from "../../common/components/Icon";
import EmailField from "../../forms/components/EmailField";
import PasswordField from "../../forms/components/PasswordField";
import SubmitButton from "../../forms/components/SubmitButton";
import Form from "../../forms/components/Form";
import useLoginWithPassword from "../hooks/useLoginWithPassword";
import FormErrors from "../../forms/components/FormErrors";
import useForm from "../../forms/hooks/useForm";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

export default function LoginForm() {
  const intl = useIntl();
  const { loginWithPassword } = useLoginWithPassword();
  const router = useRouter();
  const forgotPasswordPageId = findPageIdByFilePath("/forgot");
  const { next = "/" } = router.query || {};

  const form = useForm({
    submit: loginWithPassword,
    getSubmitErrorMessage: (error) => {
      if (error?.message?.includes("auth failed")) {
        return intl.formatMessage({
          id: "error_login",
          defaultMessage:
            "Fehlgeschlagen, bitte überprüfen Sie Benutzer/Passwort",
        });
      }
      return "";
    },
    initialValues: {
      email: null,
      password: null,
    },
  });

  return (
    <div className="row position-relative">
      <div className="col-md-4 pb-4 border-right">
        <Form form={form}>
          <h2 className="mb-2">
            <Icon
              className="icon--large mr-2"
              icon="login-2"
              fill="currentColor"
            />
            {intl.formatMessage({ id: "login", defaultMessage: "Anmelden" })}
          </h2>
          <LocalizedLink
            page={{ pageId: forgotPasswordPageId }}
            className="font-size-small link d-inline-block mb-4"
          >
            {intl.formatMessage({
              id: "forgot_password",
              defaultMessage: "Passwort vergessen?",
            })}
          </LocalizedLink>
          <EmailField
            required
            label={intl.formatMessage({
              id: "email",
              defaultMessage: "E-Mail",
            })}
          />
          <PasswordField
            disableValidation
            required
            className="mb-2"
            label={intl.formatMessage({
              id: "password",
              defaultMessage: "Passwort",
            })}
          />

          <div>
            <FormErrors />
          </div>

          <div className="mt-4">
            <SubmitButton
              label={intl.formatMessage({
                id: "login",
                defaultMessage: "Anmelden",
              })}
            />
          </div>
        </Form>
      </div>
      <div className="col-md-8 col-lg-8">
        <h2 className="mb-2">
          {intl.formatMessage({
            id: "new_at_publicare_title",
            defaultMessage: "Noch keinen Online Account?",
          })}
        </h2>
        <h4 className="mb-0">
          {intl.formatMessage({
            id: "new_at_publicare_text",
            defaultMessage:
              "Wenn Sie noch keine Online Zugangsdaten für unseren Online Shop haben, registrieren Sie sich hier.",
          })}
        </h4>
        <h4 className="mt-3">
          {intl.formatMessage({
            id: "new_at_publicare_advantages",
            defaultMessage: "Ihre Vorteile auf einen Blick:",
          })}
        </h4>
        <ul className="list mb-4">
          <li>
            {intl.formatMessage({
              id: "new_at_publicare_advantage_one",
              defaultMessage: "Schnelles und einfaches Einkaufen",
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: "new_at_publicare_advantage_two",
              defaultMessage: "Einblick in die Bestellhistorie",
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: "new_at_publicare_advantage_three",
              defaultMessage: "Zentrale Verwaltung der Kundendaten",
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: "new_at_publicare_advantage_four",
              defaultMessage: "Mehrere Mitarbeiter-Profile für Institutionen",
            })}
          </li>
        </ul>
        <div className="button-group mt-1">
          <LocalizedLink
            page={{ pageId: "/signup", query: { next, type: "private" } }}
            className="button button--secondary"
          >
            {intl.formatMessage({
              id: "registration_private",
              defaultMessage: "Registration als Privatperson",
            })}
          </LocalizedLink>
          <LocalizedLink
            className="button button--secondary"
            page={{ pageId: "/signup", query: { next, type: "corporate" } }}
          >
            {intl.formatMessage({
              id: "registration_corporate",
              defaultMessage: "Registration als Geschäftskunde",
            })}
          </LocalizedLink>
        </div>
      </div>
    </div>
  );
}
