import { gql } from "@apollo/client";

const logOut = async (apollo) => {
  try {
    sessionStorage.setItem("logout", "true");
  } catch (e) {
    /* */
  }

  await apollo.mutate({
    mutation: gql`
      mutation logout {
        logout {
          success
        }
      }
    `,
    awaitRefetchQueries: false,
  });
  await apollo.resetStore();
};

export default logOut;
