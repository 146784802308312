import { useApolloClient } from "@apollo/client";
import { useIntl } from "react-intl";

import Icon from "../../common/components/Icon";
import logout from "../utils/logout";

const AlreadyLoggedIn = ({ title }) => {
  const intl = useIntl();
  const client = useApolloClient();

  const logoutUser = async () => {
    try {
      await logout(client);
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
      return false;
    }
  };

  return (
    <div className="d-lg-flex justify-content-lg-around flex-lg-wrap position-relative">
      <div className="pb-4">
        <div className="d-flex flex-wrap align-items-center">
          <h2>
            <Icon
              className="icon--large mr-2"
              icon="login-2"
              fill="currentColor"
            />
            {title}
          </h2>
        </div>

        <button
          type="button"
          aria-label={intl.formatMessage({
            id: "already_logged_in",
            defaultMessage:
              "Sie sind bereits angemeldet. Klicken Sie hier um sich abzumelden.",
          })}
          className="button button--primary d-flex align-items-center"
          onClick={() => logoutUser()}
        >
          <Icon
            icon="logout-2"
            alt="logout icon"
            fill="currentColor"
            className="mr-2 icon--medium"
          />
          {intl.formatMessage({
            id: "already_logged_in",
            defaultMessage:
              "Sie sind bereits angemeldet. Klicken Sie hier um sich abzumelden.",
          })}
        </button>
      </div>
    </div>
  );
};

export default AlreadyLoggedIn;
