export const isStrLenGte = (str, len) => {
  return str && str?.length >= len;
};

export const strHasLowerCase = (str) => {
  return str && /[a-z]/.test(str);
};

export const strHasUpperCase = (str) => {
  return str && /[A-Z]/.test(str);
};

export const strHasNumber = (str) => {
  return str && /[0-9]/.test(str);
};

export const strHasSpecialChar = (str) => {
  return str && /[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(str);
};
